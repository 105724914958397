<template>
  <div class="card">
    <div class="card-header card-header-divider">
      Дневник Эмоций &ndash; Аналитика
    </div>
    <div class="card-body">
      <div class="analytics_controls">
        <div class="analytics_form">
          <div class="select_wrapper">
            <custom-select-2
                :label="'Период'"
                name="period"
                :options="periods"
                v-model="periodForm"
            />
          </div>
          <div class="button_wrapper">
            <button @click="getAnalyticsData" class="btn btn-accent">Получить</button>
          </div>
        </div>
        <div v-if="periodForm === 'custom'" class="custom_form">
          <custom-input :type="'date'" :label="'С'" name="start_period" :maxValue="todayDate"
                        v-model="customPeriodForm.start_date"/>
          <custom-input :type="'date'" :label="'По'" name="end_period" :minValue="customPeriodForm.start_date ?? ''"
                        :maxValue="todayDate" v-model="customPeriodForm.end_date"/>
        </div>
      </div>
      <div>
        <div class="preloader" v-if="!isLoaded">
          <div>
            <mini-preloader/>
          </div>
        </div>
        <div v-if="isLoaded && analyticsData">
          <div v-if="!analyticsData.emotions || !analyticsData.emotions.length" class="no_results">
            Нет данных
          </div>
          <div v-else class="chart_wrapper">
            <div class="chart emotions_chart">
              <bar-chart
                  :chartData="emotionData"
                  :options="emotionBarOptions"
                  :height="400"
              />
            </div>
            <div class="chart triggers_chat">
              <bar-chart
                  :chartData="triggerData"
                  :options="triggerBarOptions"
                  :height="400"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import {onBeforeMount, reactive, ref} from "@vue/runtime-core";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import {useStore} from "vuex";
import {computed} from "@vue/reactivity";
import {notify} from "@kyvg/vue3-notification";
import {BarChart} from "vue-chart-3";
import MiniPreloader from "@/components/Technical/MiniPreloader.vue";

export default {
  name: "DiaryAnalytics",
  components: {MiniPreloader, CustomInput, CustomSelect2, BarChart},
  setup() {
    const store = useStore();
    const analyticsData = computed(() => store.state.diary.diaryAnalytics);
    const periodForm = ref('week');
    const customPeriodForm = reactive({
      start_date: null,
      end_date: null
    });
    const isLoaded = ref(true);
    const today = new Date();
    const currentDate = today.getDate();
    const todayDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + (currentDate < 10 ? '0' + currentDate : currentDate);
    const periodString = ref('');

    const emotionData = computed(() => {

      if (analyticsData.value && analyticsData.value.emotions) {
        let labels = analyticsData.value.emotions.map((el) => el.value);
        let emotions = analyticsData.value.emotions.map((el) => el.count);

        return {
          labels: labels,
          datasets: [{
            label: 'Количество',
            data: emotions,
            backgroundColor: '#6ca3b7',
          }]
        }
      }
    });

    const triggerData = computed(() => {

      if (analyticsData.value && analyticsData.value.triggers) {
        let labels = analyticsData.value.triggers.map((el) => el.value);
        let emotions = analyticsData.value.triggers.map((el) => el.count);

        return {
          labels: labels,
          datasets: [{
            label: 'Количество',
            data: emotions,
            backgroundColor: '#6ca3b7',
          }]
        }
      }
    });

    const emotionBarOptions = {
      scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          min: 0,
          max: (ctx) => {
            if (analyticsData.value) {
              return analyticsData.value.emotions[0].count + 1;
            }
          },
          beginAtZero: true,
          ticks: {
            stepSize: 1,
          }
        }
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: false
        },
        title: {
          display: true,
          align: 'start',
          text: (ctx) => {
            return 'Частота испытываемых эмоций ' + periodString.value.toLowerCase()
          },
          font: {
            size: 20,
            weight: 400,
          },
          padding: {
            top: 10,
            bottom: 30
          }
        }
      }
    }

    const triggerBarOptions = {
      scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          min: 0,
          max: (ctx) => {
            if (analyticsData.value) {
              return analyticsData.value.triggers[0].count + 1;
            }
          },
          beginAtZero: true,
          ticks: {
            stepSize: 1,
          }
        }
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: false
        },
        title: {
          display: true,
          align: 'start',
          text: (ctx) => {
            return 'Причины возникновения эмоций ' + periodString.value.toLowerCase()
          },
          font: {
            size: 20,
            weight: 400,
          },
          padding: {
            top: 10,
            bottom: 30
          }
        }
      }
    }

    const periods = [
      {
        id: 'week',
        text: 'За последнюю неделю'
      },
      {
        id: 'month',
        text: 'За месяц'
      },
      {
        id: 'quarter',
        text: 'За квартал'
      },
      {
        id: 'year',
        text: 'За год'
      },
      {
        id: 'custom',
        text: 'Другой период'
      }
    ];

    const getAnalyticsData = () => {
      let dates = {};

      if (periodForm.value === 'custom') {
        if (!customPeriodForm.start_date || !customPeriodForm.end_date) {
          notify({
            text: 'Введите даты начала и конца периода',
            type: 'warning',
          });
          return;
        }

        dates = {
          start_date: customPeriodForm.start_date,
          end_date: customPeriodForm.end_date,
        };

        periodString.value = '';
      } else {
        let period = new Date();

        if (periodForm.value === 'week') {
          period.setDate(period.getDate() - 7)
        }

        if (period.value === 'month') {
          period.setMonth(period.getMonth() - 1)
        }

        if (periodForm.value === 'quarter') {
          period.setMonth(period.getMonth() - 3)
        }

        if (periodForm.value === 'year') {
          period.setFullYear(period.getFullYear() - 1)
        }

        let selectedDate = period.getDate();

        dates = {
          start_date: period.getFullYear() + '-' + period.getMonth() + '-' + (selectedDate < 10 ? '0' + selectedDate : selectedDate),
          end_date: todayDate,
        }

        periodString.value = periods.find((el) => el.id === periodForm.value).text;
      }

      isLoaded.value = false;
      store.dispatch('diary/getAnalytics', dates).then(() => {
        isLoaded.value = true;
      }).catch(() => isLoaded.value = true);
    }

    onBeforeMount(() => {
      getAnalyticsData();
    });

    return {
      periods,
      periodForm,
      todayDate,
      customPeriodForm,
      getAnalyticsData,
      isLoaded,
      analyticsData,
      emotionData,
      triggerData,
      emotionBarOptions,
      triggerBarOptions
    };
  }
}
</script>


<style scoped lang="scss">
.analytics_controls {
  margin-bottom: 50px;
}

.analytics_form {
  width: 100%;
  display: flex;
  align-items: center;
}

.select_wrapper {
  min-width: 85%
}

.custom_form {
  padding-left: 10px;
  display: flex;
}

.preloader {
  text-align: center;
  padding: 100px 50px;
}

.no_results {
  text-align: center;
  padding: 100px 0;
}

.card {
  min-height: 500px;
}

.chart_wrapper {
  display: flex;
  justify-content: space-evenly;
  column-gap: 30px;
  flex-wrap: wrap;
}

.chart {
  width: 500px;
  height: 450px;
}

@media (max-width: 975px) {
  .select_wrapper {
    min-width: 100%;
  }

  .analytics_form {
    flex-wrap: wrap;
    row-gap: 15px;
  }

  .button_wrapper {
    margin-left: auto;
  }

  .custom_form {
    flex-direction: column;
  }
}
</style>